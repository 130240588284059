import * as THREE from 'three';

export function createExtrudedShape(shape, color, extrudeSettings = {}, wireframe = false) {
    let defaultExtrudeSettings = {
        steps: 2,
        depth: 0,
        bevelEnabled: false,
        bevelThickness: 1,
        bevelSize: 1,
        bevelOffset: 0,
        bevelSegments: 1
    };

    const geometry = new THREE.ExtrudeGeometry( shape, Object.assign(defaultExtrudeSettings, extrudeSettings));
    const material = new THREE.MeshPhongMaterial({
        color:     color, 
        specular:  0x101010,
        shininess: 100,
        side:      THREE.DoubleSide,
        transparent: true
    })
    return new THREE.Mesh( geometry, material ) ;

}