import * as THREE from 'three';
import {createExtrudedShape} from './extruder';


export function createBigI({a, b, c , d, depth = 0, color = 0x0000ff}) {

    const r = b- 2 * c;

    const shape = new THREE.Shape();
    shape.moveTo( 0,0 ); //1
    shape.lineTo( c, 0 ); //2
    shape.lineTo( c, d ); //3
    shape.lineTo( b-c , d ); //4
    shape.lineTo( b-c, 0 ); //5
    shape.lineTo( b , 0); //6
    shape.lineTo( b , a ); //7
    shape.lineTo( b-c, a ); //8
    shape.lineTo( b-c, a-d ); //9
    shape.lineTo( b-c-r , a-d); //10
    shape.lineTo( b-c-r , a ); //11
    shape.lineTo( 0, a ); //12
    shape.lineTo( 0 , 0 );

    return createExtrudedShape(shape, color, {depth})

}