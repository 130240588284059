import {start as test} from './scene/scene01';

const testFunction = test;

export function start() {;

    testFunction(...arguments);
    
}

start('#container');