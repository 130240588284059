import * as THREE from 'three';

import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader";

export function loadSVG(svg) {
    
    const loader = new SVGLoader();
    const svgData = loader.parse(svg);
    //console.log("loadSVG", svg, svgData);
    // Group that will contain all of our paths
    const svgGroup = new THREE.Group();

    
    // Loop through all of the parsed paths
    svgData.paths.forEach((path, i) => {
        //console.log("xxxpath", path);
        const shapes = path.toShapes(true);
        const material = new THREE.MeshBasicMaterial({ wireframe: false, opacity: 0.5 , transparent: false} );
        material.color.setRGB( path.color.r , path.color.g, path.color.b );
        //console.log("xxxpath", path.color.r, path.color.g, path.color.b);
        // Each path has array of shapes
        shapes.forEach((shape, j) => {
            //console.log("xxx shape", shape);
            

            // Finally we can take each shape and extrude it
            const geometry = new THREE.ExtrudeGeometry(shape, {
                depth: 0,
                bevelEnabled: false
            });

            // Create a mesh and add it to the group
            const mesh = new THREE.Mesh(geometry, material);

            svgGroup.add(mesh);
        });
    });
    //console.log("svg", svgGroup);
    // Add our group to the scene (you'll need to create a scene)
    return svgGroup;

};