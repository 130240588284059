import 'babel-polyfill';

import {graphPoints, graphPointsTest} from '../data';

export async function getData(setupName = "default", version = "last") {
    
    const data = await fetch('https://innfoliolytix-screenshots.transporter.at/index.json')
    const json = await data.json();

    //if(!json[setupName]?.data[0]?.images) return graphPointsTest; //throw new Error("No Data available");

    const dataAr = json[setupName].data[0].images;
    return graphPoints.map((aPoint, index) => {
        if( dataAr[index]) {
            aPoint['img'] = dataAr[index].image;
            aPoint['caption'] = dataAr[index].name;
        }
        return aPoint;
    })

}

