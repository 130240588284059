import * as THREE from 'three';
import { cloneUniforms } from 'three';

export function createGraph(points, color) {
    
    const material = new THREE.LineBasicMaterial( { color: color, transparent:true, linewidth: 1} );
    const linePoints = [];

    points.forEach((aPoint) => {
        linePoints.push( new THREE.Vector3( aPoint.pos.x, aPoint.pos.y, aPoint.pos.z) );
    })

    const geometry = new THREE.BufferGeometry().setFromPoints( linePoints );

        
    return new THREE.Line( geometry, material );

}
