import * as THREE from 'three';
import TextSprite from '@seregpie/three.text-sprite';

export function createText(text, pos, options) {
    if(!text) return;
    const mesh = new THREE.Mesh() ;
    const textSprite = new TextSprite({
        alignment: options?.alignment || "left",
        color: options?.color || '#005faa',
        fontFamily: options?.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize: options?.size || 12,
        fontStyle: options?.fontStyle || 'normal',
        text: text,
      });
    
    mesh.position.x = pos.x;
    mesh.position.y = pos.y;
    mesh.position.z = pos.z;
    
    mesh.add(textSprite);
    return mesh;
}