
export const colorHexToRgb = (rgb) => {
    
    var hex = Number(rgb).toString(16);
    if (hex.length < 2) {
        hex = "0" + hex;
    }
    if (hex.length < 5) {
        hex = "00" + hex;
    }
    return '#' + hex;
};

export const addrand = (no, max = 1, min = 0, r = 0.3) => {
    const rand = Math.random() * r;
    const dir = Math.random() > 0.5 ? -1 : 1;
    const res = Math.floor((no + rand * dir) * 100) / 100;
    //console.log("xxx",  res);
    return Math.max(min, Math.min(max, res));
}



export const setChildren = (parent, keys, value, recursive = false) => {
    parent.children.forEach(aChild => {

        //const before = deepAccessObject(aChild, keys.split("."), 0);
        let childValue = deepAccessObject(aChild, keys.split("."), 0, value);
        //console.log("setChildren", aChild, keys.split("."), value, childValue);
        if(recursive) setChildren(aChild, keys, value, recursive);
    })
    
};


export const deepAccessObject = (object, path_to_key, type_of_function, value) => {
    switch(type_of_function){
        //Add key/modify key
        case 0: 
            if(path_to_key.length === 1){
                if(value || value === 0 || value === "") {
                    //console.log("deepAccessObject->setValue", path_to_key, value);
                    object[path_to_key[0]] = value;
                }
                //console.log("deepAccessObject->setValue #2", object[path_to_key[0]], path_to_key, value) 
                return object[path_to_key[0]];
            }else{
                if(object[path_to_key[0]])
                    return deepAccessObject(object[path_to_key[0]], path_to_key.slice(1), type_of_function, value);
                else
                    object[path_to_key[0]] = {};
            }
            break;
        //delete key
        case 1:
            if(path_to_key.length === 1){
                delete object[path_to_key[0]];
                return true;
            }else{
                if(object[path_to_key[0]])
                    return deepAccessObject(object[path_to_key[0]], path_to_key.slice(1), type_of_function, value);
                else
                    return false;
            }
            break;
        default:
            console.log("Wrong type of function");
    }
};