import * as THREE from 'three';
import {createExtrudedShape} from './extruder';


export function createBrackets(def, pos1, pos2, depth, colors) {

    //console.log("createBrackets", arguments);
    const mesh = new THREE.Mesh();



    const ub = createBracket({...def, depth: depth, color: colors[0]});
    ub.rotation.z = THREE.Math.degToRad(-90);
    ub.position.x = pos1.x;
    ub.position.y = pos1.y;
    ub.position.z = pos1.z;
    mesh.add(ub)

    const lb = createBracket({...def, depth: depth, color: colors[1] || colors[0]});
    lb.rotation.z = THREE.Math.degToRad(90);
    lb.position.x = pos2.x;
    lb.position.y = pos2.y;
    lb.position.z = pos2.z;

    mesh.add(lb)


    return mesh;
}


export function createBracket({a, b, c , d, depth, dir=0, color = 0x0000ff}) {

    const r = b- 2 * c;

    const shape = new THREE.Shape();
    switch(dir) {
        case 0:
            shape.moveTo( 0,0 ); //1
            shape.lineTo( 0, b ); //2
            shape.lineTo( a, b ); //3
            shape.lineTo( a , b-c ); //4
            shape.lineTo( a-d, b-c ); //5
            shape.lineTo( a-d , b-c-r); //6
            shape.lineTo( a , b-c-r ); //7
            shape.lineTo( a, 0 ); //8
            shape.lineTo( 0 , 0 );
            break;
        case 3:
            shape.moveTo( 0,0 ); //1
            shape.lineTo( 0, c ); //2
            shape.lineTo( -d, c ); //3
            shape.lineTo( -d , c ); //4
            shape.lineTo( a-d, b-c ); //5
            shape.lineTo( a-d , b-c-r); //6
            shape.lineTo( a , b-c-r ); //7
            shape.lineTo( a, 0 ); //8
            shape.lineTo( 0 , 0 );
            break;   
    }
    
    

    return createExtrudedShape(shape, color, {depth})

}