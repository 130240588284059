import * as THREE from 'three';


export function createImage(imgTexture, size, pos, options) {
    //console.log("createImage", imgTexture);

    const material = new THREE.MeshBasicMaterial({
        map: imgTexture,
        //opacity: 0.7,
        side: options?.side || THREE.FrontSide,
        transparent: options?.transparent ||true,
    });
    material.map.needsUpdate = true; //ADDED

    // plane
    const plane = new THREE.Mesh(new THREE.PlaneGeometry(size.width, size.height), material);
    plane.position.x = pos.x;
    plane.position.y = pos.y;
    plane.position.z = pos.z;
    plane.overdraw = true;

    if(options.rotation) {
        if(options.rotation.x) plane.rotation.x = THREE.Math.degToRad(options.rotation.x);
        if(options.rotation.y) plane.rotation.y = THREE.Math.degToRad(options.rotation.y);
        if(options.rotation.z) plane.rotation.z = THREE.Math.degToRad(options.rotation.z);
    }
    return plane;
}