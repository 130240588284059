import * as THREE from 'three';

import { addrand, setChildren } from '../helper';
import { entwickelnSVG, fallBackImage, graphPointsTest, logoTextSVG, mehrwertSVG, modelleSVG, rotateIconTextSVG } from '../data.js';

//import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import CameraControls from 'camera-controls';
import { createBigI } from '../components/bigI';
import { createBrackets } from '../components/brackets';
import { createGraph } from '../components/graph';
import { createImage } from '../components/image';
import { createText } from '../components/text';
import { getData } from '../components/dataManagement';
import { loadSVG } from '../components/svg';

const TWEEN = require('@tweenjs/tween.js');

CameraControls.install({ THREE: THREE });

const SHOW_ALL = false;
const TESTING = false;
let animationHasFinished = false;
let graphPoints;


//const WIREFRAME = false;
const SIZE = { 'width': 1000, 'height': 500, 'depth': 500 };
const IMAGES_BRACKET_SPACE = { xWidth: 0.004 * SIZE.width, yFfactor: 1.2 };
const BIG_I_BRACKET_HEIGHT = 0.25 * SIZE.height;
const BIG_I_DEF = { a: 0.045 * SIZE.height, b: 0.056 * SIZE.width, c: 0.012 * SIZE.width, d: 0.01 * SIZE.height };

const IMAGE_SIZE = { width: 0.08 * 1.9 * SIZE.width, height: 0.06 * 1.9 * SIZE.width };
const IMAGE_BRACKET_SIZE = { a: 0.006 * SIZE.height, b: IMAGE_SIZE.width + IMAGES_BRACKET_SPACE.xWidth * 2, c: 0.002 * SIZE.width, d: 0.002 * SIZE.width };
const COLORS = { LOWER_BRACKET: 0x00395B, UPPER_BRACKET: 0x005FAA, BIG_I: 0x004E86, IMAGE_BRACKETS: 0x005FAA, GRAPH: 0xd8e7f2 };
const FOV = 35;
const ASPECT = 2;  // the canvas default
const NEAR = 0.01;
const FAR = 10000;

let containerElement = null;
let labelContainerElement = null;
let canvasContainerElement = null;
let scene = null;
let camera = null;
let cameraControls = null;
let renderer = null;
let gPoints = null;

function resizeRendererToDisplaySize(renderer) {
    const canvas = canvasContainerElement;
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
        renderer.setSize(width, height, false);
    }
    return needResize;
}

export async function start(containerSelector) {

    containerElement = document.querySelector(containerSelector);

    if (TESTING) {
        graphPoints = graphPointsTest;
        buildAnimation();
    } else {


        await getData().then((data) => {
            //console.log("scene getdata xxx", data);
            if (data) {
                graphPoints = data;

                buildAnimation();
            }
        })
            .catch((error) => {

                console.log("catch error", containerElement);
                const img = document.createElement("img");
                img.src = fallBackImage;
                img.setAttribute("id", "fallbackImage");
                containerElement.appendChild(img);
                console.error(error);
            });
    }

}

function buildAnimation() {

    canvasContainerElement = document.createElement("canvas");
    containerElement.appendChild(canvasContainerElement);

    labelContainerElement = document.createElement("div");
    containerElement.appendChild(labelContainerElement);

    gPoints = graphPoints.map((aPoint, index) => {
        //console.log("xxx", index, graphPoints.length);
        aPoint['endPos'] = { x: aPoint.pos.x * SIZE.width, y: aPoint.pos.y * SIZE.height, z: aPoint.pos.z * SIZE.depth };
        if (index < graphPoints.length) aPoint['pos'] = { x: addrand(aPoint.pos.x) * SIZE.width, y: addrand(aPoint.pos.y) * SIZE.height, z: -addrand(aPoint.pos.z) * SIZE.depth };
        return aPoint;
    })

    //console.log("xxx canvasContainerElement", canvasContainerElement);
    renderer = new THREE.WebGLRenderer({ canvas: canvasContainerElement, antialias: true });
    renderer.setSize(window.innerWidth, window.innerWidth / 2);

    camera = new THREE.PerspectiveCamera(FOV, ASPECT, NEAR, FAR);
    camera.position.z = 1000;



    scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff);

    const mainMesh = new THREE.Mesh();
    scene.add(mainMesh);

    //cameraControls = new CameraControls(camera, canvasContainerElement);
    //Object.create( SpinControls.prototype, THREE.EventDispatcher.prototype );
    cameraControls = new CameraControls(camera, canvasContainerElement);
    cameraControls.addEventListener('controlstart', () => {
        //console.log("controlstart", animationHasFinished, claimLayer.visible);
        if (animationHasFinished && claimLayer.visible) {
            claimLayer.visible = false;
            rotateIcon.visible = false;
        }
    });

    //const axesHelper = new THREE.AxesHelper( 1000 );
    //scene.add( axesHelper );

    scene.add(new THREE.AmbientLight(0xcccccc));
    const light = new THREE.PointLight(0xffffff, 0.2);
    const sphere = new THREE.SphereGeometry(10, 31, 16);
    //light.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0xaaaaaa } ) ) );
    light.position.set(300, 100, 300);
    scene.add(light);




    gPoints[gPoints.length - 1]['pos'] = { x: gPoints[0].pos.x, y: gPoints[0].pos.y, z: gPoints[0].pos.z };

    /* lines */

    const graphLayer = new THREE.Mesh();

    centerLayer(graphLayer, SIZE);
    //graphLayer.add(  new THREE.AxesHelper( 1000 ) );
    mainMesh.add(graphLayer);
    let graphLine = createGraph(gPoints.slice(0, graphPoints.length), COLORS.GRAPH);

    gPoints.forEach((aPoint, index) => {
        gPoints[index]['ref'] = [{ parent: graphLine, index }];
    })
    graphLayer.add(graphLine);

    //create net lines
    let graphNet = createGraph([gPoints[1], gPoints[3], gPoints[5], gPoints[7], gPoints[9], gPoints[11]], COLORS.GRAPH);
    gPoints[1]['ref'].push({ parent: graphNet, index: 0 });
    gPoints[3]['ref'].push({ parent: graphNet, index: 1 });
    gPoints[5]['ref'].push({ parent: graphNet, index: 2 });
    gPoints[7]['ref'].push({ parent: graphNet, index: 3 });
    gPoints[9]['ref'].push({ parent: graphNet, index: 4 });
    gPoints[11]['ref'].push({ parent: graphNet, index: 5 });
    graphLayer.add(graphNet);

    let graphNet1 = createGraph([gPoints[0], gPoints[2], gPoints[4], gPoints[6], gPoints[8], gPoints[10], gPoints[12]], COLORS.GRAPH);
    gPoints[0]['ref'].push({ parent: graphNet1, index: 0 });
    gPoints[2]['ref'].push({ parent: graphNet1, index: 1 });
    gPoints[4]['ref'].push({ parent: graphNet1, index: 2 });
    gPoints[6]['ref'].push({ parent: graphNet1, index: 3 });
    gPoints[8]['ref'].push({ parent: graphNet1, index: 4 });
    gPoints[10]['ref'].push({ parent: graphNet1, index: 5 });
    gPoints[12]['ref'].push({ parent: graphNet1, index: 6 });
    graphLayer.add(graphNet1);

    //console.log("xxx graphLayer", graphLayer);
    graphLayer.visible = SHOW_ALL || false;



    /* labels */

    const labelLayer = createLabelsLayer(gPoints, SIZE);
    mainMesh.add(labelLayer);
    labelLayer.visible = SHOW_ALL || false;



    /* images */

    const imageLayer = createImagesLayer(gPoints, SIZE, IMAGE_SIZE);
    mainMesh.add(imageLayer);
    imageLayer.visible = false; //SHOW_ALL || false;



    /* image brackets */

    const bracketsLayer = createImageBracketsLayer(gPoints, IMAGE_BRACKET_SIZE, IMAGE_SIZE, IMAGES_BRACKET_SPACE);
    mainMesh.add(bracketsLayer);
    //console.log("xxx image brackets", bracketsLayer);
    bracketsLayer.visible = SHOW_ALL || false;

    /* rotate icon */

    const rotateIconLayer = new THREE.Mesh();
    const rotateIcon = loadSVG(rotateIconTextSVG);
    console.log("rotateIcon", rotateIcon);
    rotateIcon.scale.x = 0.2;
    rotateIcon.scale.y = 0.2;
    rotateIcon.scale.z = 0.2;
    rotateIcon.position.x = SIZE.width / 2 - 50;
    rotateIcon.position.y = - SIZE.height / 2 + 50;
    rotateIcon.position.z = 0;
    rotateIconLayer.add(rotateIcon);
    scene.add(rotateIconLayer);
    rotateIcon.visible = SHOW_ALL || false;

    /* logoText */

    const logoTextLayer = new THREE.Mesh();
    const logoText = loadSVG(logoTextSVG);
    //console.log("logoText", logoText);
    logoText.scale.x *= 1.64;
    logoText.scale.y *= -1.64;
    logoText.scale.z *= 1.64;
    let sizeBox = new THREE.Box3().setFromObject(logoText);
    logoText.position.y -= sizeBox.min.y;
    logoTextLayer.add(logoText);
    logoTextLayer.position.x = BIG_I_DEF.c * 5.5;
    logoTextLayer.position.y = - BIG_I_BRACKET_HEIGHT / 2 - BIG_I_DEF.c / 0.9;
    mainMesh.add(logoTextLayer);
    logoTextLayer.visible = SHOW_ALL || false;

    /* claim */

    const claimLayer = createClaimLayer();
    claimLayer.position.x = - BIG_I_DEF.b * 2.9;
    claimLayer.position.y = 0;
    mainMesh.add(claimLayer);
    claimLayer.visible = SHOW_ALL || false;


    /* logo */

    const logoLayer = new THREE.Mesh();
    const bigI = createBigI({ a: BIG_I_DEF.a, b: BIG_I_DEF.b, c: BIG_I_DEF.c, d: BIG_I_DEF.d, depth: 10, color: COLORS.BIG_I });
    //const bigIBrackets = createBracketsLayer({ });
    const bigIBrackets = createBrackets({ a: BIG_I_DEF.a * 1.1, b: (BIG_I_DEF.b + BIG_I_DEF.c * 3) * 1.1, c: BIG_I_DEF.c, d: BIG_I_DEF.d * 3 * 0.8 },
        { x: (- BIG_I_DEF.c * 1.50) * 1.2, y: BIG_I_BRACKET_HEIGHT / 2 + BIG_I_DEF.a / 2, z: 0 },
        { x: (- BIG_I_DEF.c + BIG_I_DEF.b * 1.50) * 1.1, y: - BIG_I_BRACKET_HEIGHT / 2 + BIG_I_DEF.a / 2, z: 0 },
        10, [COLORS.UPPER_BRACKET, COLORS.LOWER_BRACKET]);
    bigIBrackets.visible = SHOW_ALL || false;
    //bigIBrackets.visible = true;
    logoLayer.position.x = - BIG_I_DEF.b / 2;
    logoLayer.position.y = - BIG_I_DEF.a / 2;
    logoLayer.position.z = 0;

    logoLayer.add(bigI);
    logoLayer.add(bigIBrackets);

    mainMesh.add(logoLayer);
    logoLayer.visible = SHOW_ALL || true;




    /************************/
    /****** animations ******/
    /********************** */
    const clock = new THREE.Clock();

    canvasContainerElement.addEventListener('dblclick', onDoubleClick, false);
    function onDoubleClick(event) {
        console.log("onDoubleClick", event);
        runNextAnimation();
    }

    function animationShowBigI(dur = 500, nextDur = 500) {
        logoLayer.visible = true;
        window.setTimeout(() => runNextAnimation(), nextDur);
    }

    function animationShowGraph(dur = 500, nextDur = 500) {

        const TWEEN_FUNC = TWEEN.Easing.Sinusoidal.In;

        console.log("xxx graphLayer", graphLayer);
        graphLayer.visible = true;
        new TWEEN.Tween({ 'opacity': 0 })
            .to({ 'opacity': 1 }, dur).easing(TWEEN_FUNC)
            .onUpdate((object) => {
                //console.log("xxx ####", object);
                graphLayer.children.forEach(aChild => {
                    //console.log("xxx child", aChild.material.opacity);
                    aChild.material.opacity = object.opacity;
                });
                graphLine.geometry.needsUpdate = true;

            })
            .start();
        runNextAnimation();
    }


    function animationShowText(dur = 1000, nextDur = 7000) {

        let tOffset = dur / gPoints.length;
        let tStart = tOffset;
        gPoints.forEach((aPoint, index) => {
            //console.log("animation1", aPoint);
            if (gPoints[index]['mText']) {
                gPoints[index]['mText'].visible = false;
                window.setTimeout(() => { gPoints[index]['mText'].visible = true }, tStart)
                tStart += tOffset;
            }
        });
        labelLayer.visible = true;
        window.setTimeout(() => runNextAnimation(), nextDur);
    }


    function getMoveItemTween(aPoint, index, dur, noOfItems) {
        const TWEEN_FUNC = TWEEN.Easing.Quintic.InOut;
        const RAND_TARGET_INDEX = Math.floor(Math.random() * (graphPoints.length));
        //console.log("getMoveItemTween", RAND_TARGET_INDEX);
        let pos = gPoints[RAND_TARGET_INDEX]['endPos'];
        pos.x = Math.min(SIZE.width * 0.9, Math.max(SIZE.width * 0.1, Math.random() * SIZE.width));
        pos.y = Math.min(SIZE.height * 0.7, Math.max(SIZE.height * 0.1, Math.random() * SIZE.height));
        pos.z = - Math.random() * SIZE.depth / 2

        function setTweens(object, index) {
            if (gPoints[index]['mText']) {
                gPoints[index]['mText'].position.x = object.x;
                gPoints[index]['mText'].position.y = object.y;
                gPoints[index]['mText'].position.z = object.z;
            }
            if (gPoints[index]['mBrackets']) {
                gPoints[index]['mBrackets'].position.x = object.x;
                gPoints[index]['mBrackets'].position.y = object.y;
                gPoints[index]['mBrackets'].position.z = object.z;
            }
            if (gPoints[index]['mImage'] && !gPoints[index]['hidingImages']) {
                gPoints[index]['mImage'].position.x = object.x;
                gPoints[index]['mImage'].position.y = object.y;
                gPoints[index]['mImage'].position.z = object.z;
            }
            if (gPoints[index]['mImage2'] && !gPoints[index]['hidingImages']) {
                gPoints[index]['mImage2'].position.x = object.x;
                gPoints[index]['mImage2'].position.y = object.y;
                gPoints[index]['mImage2'].position.z = object.z;
            }
            gPoints[index]['ref'].forEach(aRef => {
                //console.log("xxx", aRef.parent);
                aRef.parent.geometry.attributes.position.setXYZ(aRef.index, object.x, object.y, object.z)
                aRef.parent.geometry.attributes.position.needsUpdate = true;
            });


        }

        //console.log("getMoveItemTween", index, noOfItems);
        new TWEEN.Tween(gPoints[index]['pos'])
            .to({ 'x': pos.x, 'y': pos.y, 'z': pos.z }, dur / 2 + Math.random() * dur * 2).easing(TWEEN_FUNC)
            .onUpdate((object) => {
                setTweens(object, index);
                //connect last with first item
                if (index === 0) setTweens(object, noOfItems - 1);
            })
            .onComplete(() => {
                getMoveItemTween(aPoint, index, dur, noOfItems)
            })
            .start();

    }


    function animationMoveItems(dur = 10000, nextDur = 3000) {

        //console.log("animationMoveItems", gPoints);
        gPoints.forEach((aPoint, index) => {
            if (index != gPoints.length - 1) getMoveItemTween(aPoint, index, dur, gPoints.length);
        });

        window.setTimeout(() => runNextAnimation(), nextDur);
    }


    function animationShowImages(dur = 9000, nextDur = 10000) {

        console.log("animationShowImages", gPoints);
        gPoints.forEach((aPoint, index) => {

            if (gPoints[index]['mImage']) gPoints[index]['mImage'].visible = false;
            if (gPoints[index]['mImage2']) gPoints[index]['mImage2'].visible = false;
            window.setTimeout(() => {

                if (gPoints[index]['mBrackets']) gPoints[index]['mBrackets'].visible = true;
                window.setTimeout(() => {
                    if (gPoints[index]['mImage']) gPoints[index]['mImage'].visible = true;
                    if (gPoints[index]['mImage2']) gPoints[index]['mImage2'].visible = true;
                    if (gPoints[index]['mText']) gPoints[index]['mText'].visible = false;
                    window.setTimeout(() => { if (gPoints[index]['mBrackets']) gPoints[index]['mBrackets'].visible = false; }, 200);
                }, 800);


            }, Math.random() * dur)

        });
        imageLayer.visible = true;
        bracketsLayer.visible = true;

        window.setTimeout(() => runNextAnimation(), nextDur);
    }



    function animationHideImages(dur = 9000, nextDur = 7000) {
        const TWEEN_FUNC = TWEEN.Easing.Sinusoidal.In;
        let tOffset = dur / gPoints.length;
        //let tStart = tOffset;
        //const MAX_Z = -3000;
        gPoints.forEach((aPoint, index) => {
            // new TWEEN.Tween(camera).to({'fov': 20}, DUR ).easing(TWEEN_FUNC)
            // .onUpdate((object)=>{
            //     camera.updateProjectionMatrix();
            // }).start();

            if (gPoints[index]['mImage']) new TWEEN.Tween({ 'opacity': 1 });
            if (gPoints[index]['mImage2']) new TWEEN.Tween({ 'opacity': 1 })

                .to({ 'opacity': 0 }, dur).easing(TWEEN_FUNC)
                .onUpdate((object) => {

                    const scaleFact = object.opacity; //Math.min(1, object.opacity * 2);

                    //console.log("xxx ####", gPoints[index]['mImage'].geometry.width);
                    if (gPoints[index]['mImage']) {
                        gPoints[index]['mImage'].material.opacity = object.opacity;
                        gPoints[index]['mImage'].geometry.scale(scaleFact, scaleFact, scaleFact);
                        gPoints[index]['mImage'].geometry.attributes.position.needsUpdate = true;
                    }

                    if (gPoints[index]['mImage2']) {
                        gPoints[index]['mImage2'].material.opacity = object.opacity;
                        gPoints[index]['mImage2'].geometry.scale(scaleFact, scaleFact, scaleFact)
                        gPoints[index]['mImage2'].geometry.attributes.position.needsUpdate = true;
                    }

                })
                .start();
        });
        imageLayer.visible = true;
        bracketsLayer.visible = true;

        window.setTimeout(() => {
            imageLayer.visible = false;
        }, dur);

        window.setTimeout(() => {
            runNextAnimation()
        }, nextDur);
    }

    function animationShowLogoText(dur = 1500, nextDur = 1500) {
        const TWEEN_FUNC = TWEEN.Easing.Quadratic.In;
        bigIBrackets.visible = true;


        logoTextLayer.visible = true;
        bigIBrackets.visible = true;
        new TWEEN.Tween({ opacity: 0 })
            .to({ opacity: 1 }, dur).easing(TWEEN_FUNC)
            .onUpdate((object) => {
                logoText.children.forEach(aChild => {
                    aChild.material.opacity = object.opacity
                })
                bigIBrackets.children.forEach(aChild => {
                    aChild.material.opacity = object.opacity
                })

            })
            .start();
        window.setTimeout(() => {
            runNextAnimation();
        }, nextDur);
    }

    let animationShowClaimFirstRun = true;
    function animationShowClaim(dur = 2000, nextDur = 1) {
        const TWEEN_FUNC = TWEEN.Easing.Quadratic.In;

        claimLayer.visible = true;
        let lastChild;
        let firstChild;
        let nextChild;

        //console.log("animationShowClaim", dur, nextDur);
        claimLayer.children.forEach(aChild => {

            //console.log("animationShowClaim->child", aChild);

            if (!firstChild) firstChild = aChild;
            if (lastChild && !nextChild) {
                nextChild = aChild;
                return;
            }
            if (aChild.visible === true) {
                lastChild = aChild;
                return;
            }

        })
        //console.log("animationShowClaim->vars", lastChild, nextChild);
        if (!nextChild) nextChild = firstChild;

        //console.log("animationShowClaim->lastchild", lastChild);
        //console.log("animationShowClaim->nextChild", nextChild);
        if(lastChild) lastChild.visible = false;
        nextChild.visible = true;
        setChildren(nextChild, 'material.opacity', 0, true);
        setChildren(nextChild, 'material.transparent', true, true);

        //{ x: addrand(aPoint.pos.x) * SIZE.width, y: addrand(aPoint.pos.y) * SIZE.height
        const centerFact = 0.6;
        claimLayer.position.x = addrand(0, SIZE.width * centerFact / 2, - SIZE.width * centerFact / 2, SIZE.width);
        claimLayer.position.y = addrand(0, SIZE.height * centerFact / 2, - SIZE.height * centerFact / 2, SIZE.height);

        //console.log("rand", SIZE, claimLayer.position);
        // console.log("claimLayer.children1", 
        // claimLayer.children[0].visible,
        // claimLayer.children[0].children[0].material.opacity,
        // claimLayer.children[0].children[0].material.transparent
        // );
        // console.log("claimLayer.children2", 
        // claimLayer.children[1].visible,
        // claimLayer.children[1].children[0].material.opacity,
        // claimLayer.children[1].children[0].material.transparent
        // );
        // console.log("claimLayer.children3", 
        // claimLayer.children[2].visible,
        // claimLayer.children[2].children[0].material.opacity,
        // claimLayer.children[2].children[0].material.transparent
        // );
        // console.log("claimLayer.children --------------------"); 

        const changeMesh = (object) => {
            claimLayer.children.forEach(aChild => {
                //console.log("tweeen #1", aChild);
                if (aChild.visible != true) return;
                //console.log("tweeen #2", aChild.visible, aChild.children[0].material.transparent, aChild.children[0].material.opacity);
                setChildren(aChild, 'material.opacity', object.opacity, true);
            })
        }

        // fade in
        new TWEEN.Tween({ opacity: 0 })
            .to({ opacity: 1 }, dur / 5 * 4).easing(TWEEN_FUNC)
            .onUpdate((object) => {
                changeMesh(object);
            })
            .onComplete((object) => {
                // fade out
                new TWEEN.Tween({ opacity: 1 })
                    .to({ opacity: 0 }, dur / 5 * 1).easing(TWEEN_FUNC)
                    .onUpdate((object) => {
                        changeMesh(object);
                    })
                    .start();
            })
            .start();

        window.setTimeout(() => {
            //console.log("window.setTimeout");
            animationShowClaim(dur, nextDur);
            if (animationShowClaimFirstRun) {
                animationShowClaimFirstRun = false;
                runNextAnimation();

            }
        }, nextDur);
    }


    function animationCameraFlight(dur = 20000, nextDur = 20000) {
        const TWEEN_FUNC = TWEEN.Easing.Quadratic.In;
        const startAzimuthAngle = cameraControls.azimuthAngle;
        const startPolarAngle = cameraControls.polarAngle;
        const move1 = () => {
            new TWEEN.Tween(cameraControls)
                .to({ azimuthAngle: 720 * THREE.MathUtils.DEG2RAD + startAzimuthAngle }, dur)
                .easing(TWEEN_FUNC)
                .onStart(function () {

                    // disable user control while the animation
                    cameraControls.enabled = false;
                    move2();

                })
                .onComplete(function () {

                    cameraControls.enabled = true;
                    //move2();
                    cameraControls.zoom(camera.zoom / 7, true);
                    //cameraControls.setLookAt(camera.position.x, camera.position.y - 30, camera.position.z - 400, 50, 100, 10, true);
                })
                .start();

        }

        const move2 = () => {
            new TWEEN.Tween(cameraControls)
                .to({ polarAngle: startPolarAngle - startPolarAngle * 0.4 }, dur / 2)
                .easing(TWEEN.Easing.Elastic.Out)
                .onComplete(function () {
                    move3()
                })
                .start();

        }

        const move3 = () => {
            new TWEEN.Tween(cameraControls)
                .to({ polarAngle: startPolarAngle }, dur / 2)
                .easing(TWEEN.Easing.Elastic.Out)
                .start();

        }


        move1();

        runNextAnimation();

    }

    function animationLast(dur = 1, nextDur = 1) {

        animationHasFinished = true;
    }

    let currentAnimation = 0;
    const animationSpeed = 1; // 1 normal
    const animationSet = [
        () => { animationShowBigI(500 / animationSpeed, 500 / animationSpeed) },
        () => { animationCameraFlight(20000 / animationSpeed, 20000 / animationSpeed) },
        () => { animationShowGraph(500 / animationSpeed, 1 / animationSpeed) },
        () => { animationMoveItems(10000 / animationSpeed, 3000 / animationSpeed) },
        () => { animationShowText(1000 / animationSpeed, 1000 / animationSpeed - (1000 / animationSpeed / 3)) },
        () => { animationShowImages(9000 / animationSpeed, 9000 / animationSpeed + 3000 / animationSpeed) },
        () => { animationHideImages(9000 / animationSpeed, 3000 / animationSpeed) },
        () => { animationShowLogoText(1500 / animationSpeed, 1500 / animationSpeed) },
        () => { animationShowClaim(6000 / animationSpeed, 6000 / animationSpeed) },        
        () => { animationLast(1, 1) }
    ];

    function runNextAnimation() {
        if (animationSet[currentAnimation]) animationSet[currentAnimation++](runNextAnimation);
    }

    function animate() {
        requestAnimationFrame(animate)

        TWEEN.update();
        const delta = clock.getDelta();
        const hasControlsUpdated = cameraControls.update(delta)
        if (hasControlsUpdated) {
            renderer.render(scene, camera);
        }
        renderer.render(scene, camera);
    }
    if (!SHOW_ALL) runNextAnimation();
    animate();

}

function createClaimLayer() {
    //console.log("createClaimLayer");
    const scale = 3;
    const claimLayer = new THREE.Mesh();

    [modelleSVG, entwickelnSVG, mehrwertSVG].forEach((item) => {
        const aWord = loadSVG(item);
        aWord.scale.x *= scale;
        aWord.scale.y *= -scale;
        aWord.scale.z *= scale;
        //let sizeBox = new THREE.Box3().setFromObject(aWord);
        //aWord.position.y -= sizeBox.min.y;
        //console.log("createClaimLayer aWord", aWord.visible);
        aWord.visible = false;
        claimLayer.add(aWord);
    })

    //console.log("logoText", logoText);
    //claimLayer.position.x = BIG_I_DEF.c * 5.5;
    //claimLayer.position.y = - BIG_I_BRACKET_HEIGHT / 2 - BIG_I_DEF.c / 0.9;
    return claimLayer;
}

function createClaimLayer_() {
    //console.log("createClaimLayer");
    const claimLayer = new THREE.Mesh();
    const model = loadSVG(modelleSVG);
    //console.log("logoText", logoText);
    model.scale.x *= 1.64;
    model.scale.y *= -1.64;
    model.scale.z *= 1.64;
    let sizeBox = new THREE.Box3().setFromObject(model);
    model.position.y -= sizeBox.min.y;
    claimLayer.add(model);
    claimLayer.add(model.clone());
    claimLayer.add(model.clone());
    claimLayer.position.x = BIG_I_DEF.c * 5.5;
    claimLayer.position.y = - BIG_I_BRACKET_HEIGHT / 2 - BIG_I_DEF.c / 0.9;
    return claimLayer;

}


function createLabelsLayer(gPoints, size) {
    console.log("createLabelsLayer", gPoints);
    const mesh = new THREE.Mesh();
    centerLayer(mesh);

    gPoints.forEach((aPoint, index) => {
        if (aPoint.caption) {
            gPoints[index]['mText'] = createText(aPoint.caption, aPoint.pos);
            mesh.add(gPoints[index]['mText']);
        }
    });

    return mesh;
}

function createImagesLayer(gPoints, size, image_size) {

    const mesh = new THREE.Mesh();
    centerLayer(mesh);

    let promisesTexture = [];
    let lastImage = gPoints[gPoints.length - 2].img;
    gPoints.forEach((aPoint, index) => {

        if (aPoint.img) {
            //console.log("xxx"+index, aPoint.img);
            promisesTexture.push(loadTexture(aPoint.img).then(
                texture => { gPoints[index]['mImage'] = createImage(texture, image_size, aPoint.pos, { side: THREE.FrontSide }); }
            ));
            promisesTexture.push(loadTexture(lastImage).then(
                texture => { gPoints[index]['mImage2'] = createImage(texture, image_size, { ...aPoint.pos, y: -1 }, { side: THREE.FrontSide, rotation: { y: 180 } }); }
            ));

            lastImage = aPoint.img;
        }
    })
    Promise.all(promisesTexture).then(() => {

        gPoints.forEach((aPoint, index) => {
            if (aPoint.img) {
                mesh.add(gPoints[index]['mImage']);
                mesh.add(gPoints[index]['mImage2']);

            }
        });
    });
    return mesh;
}

function createImageBracketsLayer(gPoints, bracketSize, size, bracketSpace) {

    const mesh = new THREE.Mesh();
    centerLayer(mesh);

    gPoints.forEach((aPoint, index) => {
        //if(aPoint.caption === "CNN") {
        if (aPoint.img) {
            gPoints[index]['mBrackets'] = createBrackets(bracketSize,
                { x: -size.width / 2 - bracketSpace.xWidth, y: size.height / 2 * bracketSpace.yFfactor, z: 0 },
                { x: (size.width + bracketSpace.xWidth * 2) / bracketSpace.xWidth * 2, y: -size.height / 2 * bracketSpace.yFfactor, z: 0 },
                0, [COLORS.IMAGE_BRACKETS]);

            gPoints[index]['mBrackets'].position.x = aPoint.pos.x;
            gPoints[index]['mBrackets'].position.y = aPoint.pos.y;
            gPoints[index]['mBrackets'].position.z = aPoint.pos.z;
            gPoints[index]['mBrackets'].visible = false;
            mesh.add(gPoints[index]['mBrackets']);
        }
        //}

    })

    return mesh;
}

function centerLayer(layer) {
    layer.position.x = - SIZE.width / 2;
    layer.position.y = - SIZE.height / 3;
    //const axesHelper = new THREE.AxesHelper( 500 );
    //layer.add( axesHelper );
}

function loadTexture(url) {
    console.log("loadTexture", url);
    return new Promise((resolve) => {
        const loader = new THREE.TextureLoader();
        //allow cross origin loading
        loader.setCrossOrigin("anonymous");
        loader.load(url, resolve, undefined, (err) => { console.error(err); });
    });
}










